import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/database";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTimes } from "react-icons/fa";
import "./DataDisplayPage.css";

const DataDisplayPage = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editedName, setEditedName] = useState("");
  const [editedPhone, setEditedPhone] = useState("");
  const [editedGrade, setEditedGrade] = useState("");
  const [editedClasss, setEditedClasss] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [visibleButtons, setVisibleButtons] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [customMessage, setCustomMessage] = useState("");

  const [showStudentsWithoutPhoneNumber, setShowStudentsWithoutPhoneNumber] =
    useState(false);

  const toggleShowStudentsWithoutPhoneNumber = () => {
    setShowStudentsWithoutPhoneNumber(!showStudentsWithoutPhoneNumber);
  };

  useEffect(() => {
    const database = firebase.database();

    const fetchData = async () => {
      database.ref("students").on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const studentsData = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setStudents(studentsData);
        }
      });

      const messagesRef = database.ref("messages");

      messagesRef.on("value", (snapshot) => {
        const messagesData = snapshot.val();
        if (messagesData) {
          const messagesList = Object.keys(messagesData).map((key) => ({
            id: key,
            title: messagesData[key].title,
            content: messagesData[key].content,
          }));
          setMessages(messagesList);
        }
      });

      return () => {
        messagesRef.off();
      };
    };

    fetchData();

    return () => {
      database.ref("students").off("value");
    };
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this student data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const database = firebase.database();

        database
          .ref(`students/${id}`)
          .remove()
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Student data has been deleted.",
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: `An error occurred while deleting the student: ${error}`,
              icon: "error",
            });
          });
      }
    });
  };

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const filteredStudents = students.filter((student) => {
    if (selectedGrade !== "" && student.grade !== selectedGrade) {
      return false;
    }
    return (
      student.studentName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (showStudentsWithoutPhoneNumber ? student.phoneNumber === "" : true)
    );
  });

  const handleEdit = (student) => {
    setSelectedStudent(student);
    setEditedName(student.studentName);
    setEditedPhone(student.phoneNumber);
    setEditedGrade(student.grade);
    setEditedClasss(student.classs);
    setShowModal(true);
  };

  const handleSaveChanges = () => {
    const database = firebase.database();

    database
      .ref(`students/${selectedStudent.id}`)
      .update({
        studentName: editedName,
        phoneNumber: editedPhone,
        grade: editedGrade,
        classs: editedClasss,
      })
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Data updated successfully.",
          icon: "success",
        });
        setShowModal(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: `An error occurred while updating the data: ${error}`,
          icon: "error",
        });
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };
  // const makeCall = (students) => {
  //   window.open(`tel:+${students.phoneNumber}`);
  // };

  const makeCall = (students) => {
    window.location.href = `tel:+${students.phoneNumber}`;
};


  const openWhatsApp = (students) => {
    const fixedText = "عزيزي ولي أمر الطالب:";
    const schoolName = `*المرسل: ${students.school}*`;
    let finalMessage = `${fixedText}\n${students.studentName}`;

    if (selectedMessage) {
      finalMessage += `\n${selectedMessage.content}`;
    }

    if (customMessage.trim() !== "") {
      finalMessage += `\n${customMessage}`;
    }

    finalMessage += `\n${schoolName}`;

    window.open(
      `https://api.whatsapp.com/send?phone=${
        students.phoneNumber
      }&text=${encodeURIComponent(finalMessage)}`
    );
  };

  const toggleButtons = (index) => {
    if (visibleButtons === index) {
      setVisibleButtons(null);
    } else {
      setVisibleButtons(index);
    }
  };

  const totalRows = filteredStudents.length;
  const handleClear = () => {
    setCustomMessage("");
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12">
          <select
            className="form-select"
            value={selectedMessage ? selectedMessage.id : ""}
            onChange={(event) => {
              const messageId = event.target.value;
              const selected = messages.find(
                (message) => message.id === messageId
              );

              setSelectedMessage(selected);
              setCustomMessage(selected ? "" : customMessage);
            }}
          >
            <option value="">اختر رسالة</option>
            {messages.map((message) => (
              <option key={message.id} value={message.id}>
                {message.title}
              </option>
            ))}
          </select>

          <textarea
            className="form-control mt-2"
            value={selectedMessage ? selectedMessage.content : customMessage}
            onChange={(event) => {
              if (!selectedMessage) {
                setCustomMessage(event.target.value);
              }
            }}
            placeholder="أدخل رسالتك هنا..."
            rows={2}
          />
          {customMessage && (
            <FaTimes className="clear-icon" onClick={handleClear} />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <select
            className="form-select form-select-md mt-3"
            value={selectedGrade}
            onChange={handleGradeChange}
          >
            <option value="">جميع المراحل</option>
            <option value="الأول">الأول</option>
            <option value="الثاني">الثاني</option>
            <option value="الثالث">الثالث</option>
            <option value="الرابع">الرابع</option>
            <option value="الخامس">الخامس</option>
            <option value="السادس">السادس</option>
          </select>
          <input
            className="form-control mt-3"
            type="text"
            placeholder="البحث"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <FaTimes className="clear-icon" onClick={handleClearSearch} />
          )}

          <div className="buttons">
            <p className="mt-2">عدد الطلاب: {totalRows}</p>
            <button
              type="button"
              className="btn btn-danger"
              onClick={toggleShowStudentsWithoutPhoneNumber}
            >
              {showStudentsWithoutPhoneNumber ? "إخفاء الفاقد" : "إظهار الفاقد"}
            </button>
          </div>
        </div>
      </div>

      <table className="table table-hover mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th className="text-center">اسم الطالب</th>
            <th className="text-center d-none d-md-table-cell">الصف</th>
            <th className="text-center d-none d-md-table-cell">الفصل</th>
            <th className="text-center d-none d-md-table-cell">الجوال</th>
            <th className="text-center d-none d-md-table-cell">الهوية</th>
            <th className="text-center d-none d-md-table-cell">المدرسة</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student, index) => (
            <tr key={student.id}>
              <td>{index + 1}</td>
              <td onClick={() => toggleButtons(index)} className="text-center">
                {student.studentName}
                {visibleButtons === index && (
                  <div className="buttons">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => makeCall(student)}
                    >
                      <MdCall />
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => openWhatsApp(student)}
                    >
                      <IoLogoWhatsapp />
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => handleEdit(student)}
                    >
                      <MdModeEdit />
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDelete(student.id)}
                    >
                      <MdDelete />
                    </button>
                  </div>
                )}
              </td>

              <td className="text-center d-none d-md-table-cell">
                {student.grade}
              </td>
              <td className="text-center d-none d-md-table-cell">
                {student.classs}
              </td>
              <td className="text-center d-none d-md-table-cell">
                {student.phoneNumber}
              </td>
              <td className="text-center d-none d-md-table-cell">
                {student.studID}
              </td>
              <td className="text-center d-none d-md-table-cell">
                {student.school}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal && selectedStudent} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل بيانات طالب</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              الاسم
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Name"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              الجوال
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Phone"
              value={editedPhone}
              onChange={(e) => setEditedPhone(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              الصف
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Grade"
              value={editedGrade}
              onChange={(e) => setEditedGrade(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              الفصل
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Class"
              value={editedClasss}
              onChange={(e) => setEditedClasss(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            تراجع
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            حفظ التعديلات
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataDisplayPage;
