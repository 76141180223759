import React, { useRef } from "react";
import FirebaseTablesStats from "../FirebaseTablesStats/FirebaseTablesStats";
import firebase from "firebase/compat/app";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import "firebase/database";

import "./ExcelToFirebase.css";

const ExcelToFirebase = () => {
  const fileInputRef = useRef(null);

  const handleDataUpdate = () => {
    const files = fileInputRef.current.files;
    const readers = [];
    for (let i = 0; i < files.length; i++) {
      readers.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
            const secondWorksheet = workbook.Sheets[workbook.SheetNames[1]];
            const school = firstWorksheet["C4"] ? firstWorksheet["C4"].w : "";
            const jsonData = XLSX.utils.sheet_to_json(secondWorksheet, {
              header: 1,
              range: 4,
            });
            const formattedData = jsonData
              .map((row) => {
                if (row.filter((cell) => cell !== "").length === 0) {
                  return null;
                }
                let grade = row[3] !== undefined ? row[3] : "";
                switch (grade) {
                  case "0125":
                    grade = "الأول";
                    break;
                  case "0225":
                    grade = "الثاني";
                    break;
                  case "0325":
                    grade = "الثالث";
                    break;
                  case "0430":
                    grade = "الرابع";
                    break;
                  case "0530":
                    grade = "الخامس";
                    break;
                  case "0630":
                    grade = "السادس";
                    break;
                  default:
                    break;
                }
                return {
                  phoneNumber: row[1] !== undefined ? row[1] : "",
                  classs: row[2] !== undefined ? row[2] : "",
                  grade: grade,
                  studentName: row[4] !== undefined ? row[4] : "",
                  studID: row[5] !== undefined ? row[5] : "",
                  school: school,
                };
              })
              .filter((row) => row !== null);
            resolve(formattedData);
          };
          reader.readAsArrayBuffer(files[i]);
        })
      );
    }
    Promise.all(readers)
      .then((results) => {
        const mergedData = results.flat();
        const database = firebase.database();
        database.ref("students").once("value", (snapshot) => {
          const currentData = snapshot.val() || [];
          const updatedData = currentData.concat(mergedData);
          database
            .ref("students")
            .set(updatedData)
            .then(() => {
              Swal.fire({
                title: "نجاح!",
                text: "تم رفع جميع الملفات بنجاح إلى Firebase.",
                icon: "success",
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "خطأ!",
                text: `حدث خطأ أثناء رفع الملفات إلى Firebase: ${error}`,
                icon: "error",
              });
            });
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "خطأ!",
          text: `حدث خطأ أثناء قراءة الملفات: ${error}`,
          icon: "error",
        });
      });
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <input
              className="form-control"
              type="file"
              ref={fileInputRef}
              accept=".xlsx, .xls"
              multiple
            />
            <button className="btn btn-primary mt-3" onClick={handleDataUpdate}>
              رفع البيانات
            </button>
          </div>
          <FirebaseTablesStats />
        </div>
      </div>
    </>
  );
};

export default ExcelToFirebase;
