import "./Home.css";
import Navbar from "../Navbar/Navbar";
import DataDisplayPage from "../DataDisplayPage/DataDisplayPage";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <DataDisplayPage />
      </div>
    </>
  );
};

export default Home;
