import React from "react";
import { FaYoutube, FaTelegram, FaChrome } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

import x from "../../img/x-social.png";

const Footer = () => {
  return (
    <section className="mt-5 mb-5">
      <footer className="text-light mt-5">
        <div className="container">
          <div className="row text-center">
            <p>© ابتدائية جسر الملك فهد بالخبر</p>
            <div>
              <a
                href="https://www.youtube.com/channel/UCna1vOBKAGd3u9F8mJYEXDQ"
                className="text-light me-2"
                style={{ fontSize: "1.6rem" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </a>
              <a
                href="https://x.com/MOE_EPR_04_0017"
                className="text-light me-2 icon-link"
                style={{ fontSize: "1.5rem" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={x} width={23} alt="" />
              </a>
              <a
                href="https://t.me/kingfahadjasirschool"
                className="text-light me-2 icon-link"
                style={{ fontSize: "1.5rem" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTelegram />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=966583401510"
                className="text-light me-2"
                style={{ fontSize: "1.6rem" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoWhatsapp />
              </a>
              <a
                href="https://aljesr-schools.blogspot.com/"
                className="text-light me-2"
                style={{ fontSize: "1.5rem" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaChrome />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
