import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/database";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import Navbar from "../Navbar/Navbar";
import { Button, Modal } from "react-bootstrap";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editedTitle, setEditedTitle] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");

  const exportToExcel = () => {
    const data = messages.map((message) => [message.title, message.content]);
    const ws = XLSX.utils.aoa_to_sheet([["title", "content"], ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "رسائل");
    XLSX.writeFile(wb, "messages.xlsx");
  };

  const importFromExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const importedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      importedData.slice(1).forEach((row) => {
        const [title, content] = row;
        firebase.database().ref("messages").push({
          title: title,
          content: content,
        });
      });

      Swal.fire("تم الاستيراد!", "تم استيراد البيانات بنجاح.", "success");
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const messagesRef = firebase.database().ref("messages");
      messagesRef.on("value", (snapshot) => {
        const messagesData = snapshot.val();
        if (messagesData) {
          const messagesArray = Object.entries(messagesData).map(
            ([key, value]) => ({ id: key, ...value })
          );
          setMessages(messagesArray);
        }
      });
    };

    fetchMessages();

    return () => {
      firebase.database().ref("messages").off();
    };
  }, []);

  const handleAddMessage = () => {
    const database = firebase.database();
    database
      .ref("messages")
      .push({
        title: messageTitle,
        content: messageContent,
      })
      .then(() => {
        Swal.fire({
          title: "تم بنجاح!",
          text: "تم إضافة الرسالة بنجاح  .",
          icon: "success",
        });
        setMessageTitle("");
        setMessageContent("");
      })
      .catch((error) => {
        Swal.fire({
          title: "خطأ!",
          text: "ليس لديك صلاحية، يرجى التواصل مع المطور",
          icon: "error",
        });
      });
  };

  const handleEdit = (message) => {
    setSelectedMessage(message);
    setEditedTitle(message.title);
    setEditedContent(message.content);
    setShowModal(true);
  };

  const handleSaveEdit = () => {
    firebase.database().ref(`messages/${selectedMessage.id}`).update({
      title: editedTitle,
      content: editedContent,
    })
    .then(() => {
      setShowModal(false);
      Swal.fire("تم الحفظ!", "تم حفظ التعديلات بنجاح.", "success");
    })
    .catch((error) => {
      Swal.fire({
        title: "حدث خطأ",
        text: "ليس لديك صلاحية، يرجى التواصل مع المطور",
        icon: "error",
      });
    });
  };

  const handleDelete = (message) => {
    Swal.fire({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف هذه الرسالة نهائياً!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "تراجع",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم، احذفها!",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.database().ref(`messages/${message.id}`).remove()
          .then(() => {
            Swal.fire("تم الحذف!", "تم حذف الرسالة بنجاح.", "success");
          })
          .catch((error) => {
            Swal.fire({
              title: "حدث خطأ",
              text: "ليس لديك صلاحية، يرجى التواصل مع المطور",
              icon: "error",
            });
          });
      }
    });
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row mt-5">
          <div className="col">
            <h2 className="text-center">رسالة جديدة</h2>
            <input
              className="form-control mt-3"
              type="text"
              value={messageTitle}
              onChange={(e) => setMessageTitle(e.target.value)}
              placeholder="عنوان الرسالة"
            />
            <textarea
              className="form-control mt-3"
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
              placeholder="نص الرسالة"
            ></textarea>
            <button className="btn btn-primary mt-3" onClick={handleAddMessage}>
              حفظ الرسالة
            </button>
          </div>
        </div>
      </div>
      <div className="container msg">
        <div className="row text-center">
          <div className="col">
            <h2 className="mt-5 mb-3">قائمة الرسائل</h2>
            <div className="row row-cols-2">
          <div className="col">
            <button className="btn btn-success mt-3" onClick={exportToExcel}>
              تصدير الرسائل إلى Excel
            </button>
          </div>
          <div className="col">
            <label htmlFor="file-upload" className="btn btn-primary mt-3">
              استيراد الرسائل من Excel
              <input
                id="file-upload"
                type="file"
                accept=".xlsx, .xls"
                style={{ display: "none" }}
                onChange={(e) => importFromExcel(e.target.files[0])}
              />
            </label>
          </div>
        </div>
            <table className="table table-hover mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>عنوان الرسالة</th>
                  <th>نص الرسالة</th>

                  <th className="text-center d-none d-md-table-cell">إجراءات</th>
                </tr>
              </thead>
              <tbody>
                {messages.map((message, index) => (
                  <tr key={message.id}>
                    <td>{index + 1}</td>
                    <td>{message.title}</td>
                    <td style={{ width: '700px' }}>{message.content}</td>
                    <td className="text-center d-none d-md-table-cell">
                      <Button
                        variant="warning"
                        onClick={() => handleEdit(message)}
                      >
                        تعديل
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(message)}
                      >
                        حذف
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>تعديل الرسالة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="recipient-name" className="col-form-label">
              عنوان الرسالة
            </label>
            <input
              className="form-control"
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />

            <label htmlFor="recipient-name" className="col-form-label">
              نص الرسالة
            </label>
            <textarea
              className="form-control"
              as="textarea"
              rows={3}
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            إلغاء
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            حفظ التعديلات
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Messages;
