import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdWifiCalling3 } from "react-icons/md";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row text-center mt-5">
          <div className="col-12">
            <img
              className="logo"
              src="./AljesrIcon.png"
              width={200}
              alt="AljesrSchools"
            />

            <h1 className="text-center mt-5">الدعم الفني</h1>
            <p className="text-center mt-3">
              للدعم الفني يُرجى التواصل مع علي الخشرمي
            </p>
          </div>
        </div>
        <div className="grad mt-3">
          <a href="tel:0583401510" className="btn btn-primary">
            <MdWifiCalling3 />
          </a>
          <a
            href="https://wa.me/966583401510"
            className="btn btn-success"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="container">
        <div className="row text-center mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Contact;
