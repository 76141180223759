import React, { useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "./LoginPage.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const LoginPage = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || "/";

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      navigate(redirectPath);
    } catch {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "فشل تسجيل الدخول",
      });
    }

    setLoading(false);
  }

  return (
    <div className="container" dir="rtl">
      <Header/>
      <form className="glass" onSubmit={handleSubmit}>
        <h3 className="mb-5">تسجيل الدخول</h3>
        <input
          className="input"
          id="email"
          ref={emailRef}
          required
          placeholder="البريد الإلكتروني"
        />
        <input
          className="input"
          id="password"
          type="password"
          ref={passwordRef}
          required
          placeholder="كلمة المرور"
        />
        <button className="button" disabled={loading}>
          دخول
        </button>
      </form>
      <Footer />
    </div>
  );
};

export default LoginPage;
