import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/database";
import Swal from "sweetalert2";

const FirebaseTablesStats = () => {
  const [tables, setTables] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    const fetchTables = async () => {
      const db = firebase.database();
      const tablesRef = db.ref("/");
      tablesRef.on("value", (snapshot) => {
        const tablesData = snapshot.val();
        if (tablesData) {
          const tablesList = Object.keys(tablesData).map((key) => ({
            name: key,
            count: Object.keys(tablesData[key]).length,
          }));
          setTables(tablesList);
          setDataLoaded(true);
        } else {
          setTables([]);
          setDataLoaded(false);
        }
      });
    };

    fetchTables();
    return () => {
      firebase.database().ref("/").off();
    };
  }, []);

  const deleteTable = (tableName) => {
    Swal.fire({
      title: "هل أنت متأكد؟",
      text: "سيتم حذف الجدول نهائياً!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "نعم، احذفه!",
      cancelButtonText: "إلغاء",
    }).then((result) => {
      if (result.isConfirmed) {
        firebase.database().ref(`/${tableName}`).remove();
        Swal.fire("تم الحذف!", "تم حذف الجدول بنجاح.", "success");
      }
    });
  };

  return (
    <div className="container">
      <div className="row mt-5 text-center">
        <div className="col">
          <h2>قائمة الجداول في قاعدة البيانات</h2>
          {dataLoaded ? (
            <table className="table table-hover mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>اسم الجدول</th>
                  <th>عدد المحتويات</th>
                  <th className="text-center d-none d-md-table-cell">إجراء</th>
                </tr>
              </thead>
              <tbody>
                {tables.map((table, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <strong>{table.name}</strong>
                    </td>
                    <td>{table.count}</td>
                    <td className="text-center d-none d-md-table-cell">
                      <button className="btn btn-danger" onClick={() => deleteTable(table.name)}>
                        حذف الجدول
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>لا توجد بيانات لعرضها</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirebaseTablesStats;
