import React from 'react';


const Header = () => {
  return (

    <div className="text-center">
      <img src="./AljesrIcon.png" width={120} alt="School Logo"/>
    </div>
  );
};

export default Header;