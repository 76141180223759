import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "فشل تسجيل الخروج",
      });
    }
  }

  return (
    <div className="navbar">
      <div className="logo">{currentUser && currentUser.email}</div>
      <button className="menu-button" onClick={() => setShowMenu(!showMenu)}>
      <HiOutlineMenuAlt3 />
      </button>

      <div className={`menu ${showMenu ? "show" : ""}`}>
        <Link to="/">منصة الإرسال</Link>
        <Link to="/dashboard">لوحة التحكم</Link>
        <Link to="/messages">الرسائل</Link>
        <Link to="/contact">الدعم الفني</Link>
        <Link to=""  onClick={handleLogout}>
          تسجيل الخروج
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
