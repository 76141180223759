import React from "react";
import "./DashboardPage.css";
import Navbar from "../Navbar/Navbar";
import ExcelToFirebase from "./ExcelToFirebase";
const DashboardPage = () => {
  return (
    <>
    <Navbar/>
    <div className="container">
      <div className="row mt-5">
      <h2 className="mt-2 text-center mb-5">رفع بيانات الطلاب من نظام نور</h2>
        <ExcelToFirebase/>
      </div>
    </div>
    </>
    
  );
};

export default DashboardPage;
